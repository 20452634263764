var moduleName = 'ctlg';

module.exports = function () {
  [].slice.call(document.querySelectorAll('.js-ctlg')).forEach((wrapper) => {
    if (wrapper.dataset[moduleName + 'Init'] === 'true') return;
    wrapper.dataset[moduleName + 'Init'] = 'true';

    var title = wrapper.querySelector('.js-ctlg-title');
    var content = wrapper.querySelector('.js-ctlg-content');

    showOrHide();

    var windowWidth = window.innerWidth;

    window.addEventListener('resize', function () {
      if (windowWidth === window.innerWidth) {
        return;
      }

      windowWidth = window.innerWidth
      showOrHide();
    });

    title.addEventListener('click', function () {
      if (wrapper.classList.contains('ctlg_open')) {
        hide();
      } else {
        show();
      }
    });

    function showOrHide() {
      if (window.innerWidth < 980) {
        hide();
      } else if (wrapper.classList.contains('ctlg_closed-by-default')) {
        hide(0);
      } else {
        show();
      }
    }

    function show(speed = 300) {
      $(content).slideDown(speed);
      wrapper.classList.add('ctlg_open');
    }

    function hide(speed = 300) {
      $(content).slideUp(speed, function () {
        wrapper.classList.remove('ctlg_open');
      });
    }
  });
};