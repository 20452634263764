var React = require('react');

class PointsMap extends React.Component {
    componentDidMount() {
        this.map = new ymaps.Map(this.refs.mapWrap, {
            center   : [55.725285, 37.9548],
            zoom     : 15,
            controls : ['zoomControl', 'trafficControl', 'fullscreenControl']
        });

        this.map.behaviors.disable('scrollZoom');
        this.updatePoints(this.props.points);
    }

    componentWillReceiveProps(newProps) {
        if(newProps.points !== this.props.points) {
            this.updatePoints(newProps.points);
        }
        if((newProps.targetPoint || newProps.targetPoint === 0)) {
            this.map.setCenter(this.props.points[newProps.targetPoint].coordinates, 17, {
                checkZoomRange : true
            })
                .then(() => this.objectManager.objects.balloon.open(newProps.targetPoint));
        }
    }

    updatePoints(points) {
        var objectManager = new ymaps.ObjectManager({
            clusterize: true,
            gridSize: 32
        });
        var featureCollection = {
            type: "FeatureCollection",
            features : points.map((item, i) => ({
                type: 'Feature',
                id: i,
                geometry: {
                    type: 'Point',
                    coordinates: item.coordinates
                },
                properties: {
                    balloonContentHeader: item.name,
                    balloonContentBody: item.info,
                    balloonContentFooter: `<a href="${item.link}" target="_blank">${item.link}</a>`,
                    hintContent: item.name
                }
            }))
        };

        objectManager.objects.options.set('preset', 'islands#redDotIcon');
        objectManager.clusters.options.set('preset', 'islands#redClusterIcons');

        objectManager.add(featureCollection);

        this.map.geoObjects.removeAll();
        this.map.geoObjects.add(objectManager);
        this.objectManager = objectManager;
        ymaps.geoQuery(featureCollection).applyBoundsToMap(this.map, {
            checkZoomRange : true
        });
    }

    render() {
        return <div className="location__map" ref="mapWrap"></div>
    }
}

PointsMap.propTypes = {
    points : React.PropTypes.arrayOf(
        React.PropTypes.shape({
            name : React.PropTypes.string,
            info : React.PropTypes.string,
            link : React.PropTypes.string,
            coordinates : React.PropTypes.arrayOf(React.PropTypes.number)
        })
    ),
    targetPoint : React.PropTypes.number
};

module.exports = PointsMap;