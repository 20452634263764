'use strict';

function init() {
	Array.from(document.querySelectorAll('.js-set-background')).forEach((wrapper) => {
		const settings = JSON.parse(wrapper.innerHTML);

		function getUrl() {
			if (window.innerWidth < 600) {
				return settings.s;
			}
			if (window.innerWidth < 980) {
				return settings.m;
			}
			return settings.l;
		}

		wrapper.parentElement.style.backgroundImage = `url(${getUrl()})`;
	});
}

module.exports = function () {
	window.addEventListener('resize', init);
	init();
};
