"use strict";

module.exports = function () {
	$('.contacts__toggle').on('click', function(){
		var $map = $(this).parents('.contacts').find('.contacts__map');

		if ($(this).is('.contacts__toggle_active')) {
			$map.slideUp(300);
			$(this).removeClass('contacts__toggle_active');
		} else {
			$map.slideDown(300);
			$(this).addClass('contacts__toggle_active');
		}

		return false;
	});
};