var React = require('react');

class PointsList extends React.Component {
    render() { //TODO: дописал проверку, 1500 точек, или менее
        return <div className="rtls">
            {this.props.points.length < 1500 && this.props.points.map((item, i) => <div className="rtls__item" key={i}>
                <header className="rtls__header">
                    <div className="rtls__name">{item.name}</div>
                    <span
                        className="rtls__maplink"
                        onClick={this.props.onPointLinkClick.bind(null, i)}
                    >
						{this.props.lang.showOnMap}
					</span>
                </header>
                <div className="rtls__adr">{item.info}</div>
                <div className="rtls__url">
                    <a href={item.link} target="_blank">{item.link}</a>
                </div>
            </div>)}
        </div>
    }
}

PointsList.propTypes = {
    points : React.PropTypes.arrayOf(
        React.PropTypes.shape({
            name : React.PropTypes.string,
            info : React.PropTypes.string,
            link : React.PropTypes.string,
            coordinates : React.PropTypes.arrayOf(React.PropTypes.number)
        })
    ),
    onPointLinkClick : React.PropTypes.func
};

module.exports = PointsList;