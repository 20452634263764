"use strict";

var moduleName = 'points-map';

var scriptLoader = require('scriptjs');

var React = require('react');
var ReactDOM = require('react-dom');

var App  = require('./App.jsx');

function getRegion (countries = [], address = []) {

    if (!Array.prototype.findIndex) {
        Array.prototype.findIndex = function(predicate) {
            if (this == null) {
                throw new TypeError('Array.prototype.findIndex called on null or undefined');
            }
            if (typeof predicate !== 'function') {
                throw new TypeError('predicate must be a function');
            }
            var list = Object(this);
            var length = list.length >>> 0;
            var thisArg = arguments[1];
            var value;

            for (var i = 0; i < length; i++) {
                value = list[i];
                if (predicate.call(thisArg, value, i, list)) {
                    return i;
                }
            }
            return -1;
        };
    }

    let countryId = -1,
        provinceId = -1,
        id = null;

    const country = address.findIndex(item => item.kind === 'country');

    const countryInArray = countries.findIndex(item => {
        if (item.name === address[country].name) {
            // countryId = Number(item.id);
            id = Number(item.id);
            return true;
        }
        return false;
    });

    if (countryInArray !== -1) {
        address.findIndex(item => {
            if (item.kind === 'province') {
                provinceId = countries[countryInArray].regions.findIndex(region => {
                    if (region.name === item.name) {
                        // provinceId = Number(region.id);
                        id = Number(region.id);
                        return true;
                    }
                });
                if(provinceId !== -1)
                    return true;
            }
            return false;
        });
    }
    return {countryId: countryInArray, provinceId, id};
}

function getPointsAndRender({
    location,
    data,
    $reactContainer,
}) {
    $.get(data.lang.requestUrl, {
        action : 'select',
        id : location.id,
        retail : false,
        wholesale : false
    }, 'json')
    .then((points)=>{
        if(Array.isArray(points))
            data.bootstrapPoints = points;
        ReactDOM.render(<App data={data} userLocation={location}/>, $reactContainer[0], function () {
            $reactContainer.addClass('init');
        });
    });
}

module.exports = function () {
    $('[points-map]').each(function () {
        if ($.data(this, moduleName + '-init')) return;
        $.data(this, moduleName + '-init', true);

        const MAP_LANG = $('html').attr('lang') === 'en' ? 'en' : 'ru_RU';

        scriptLoader(`https://api-maps.yandex.ru/2.1/?lang=${MAP_LANG}&apikey=c614bd24-2bb6-4a37-a680-ab478f57d6f4`, () => {
            ymaps.ready(() => {
                var $jsonContainer = $(this),
                    $reactContainer = $jsonContainer.siblings('[points-map-container]'),
                    data = JSON.parse($jsonContainer.html());

                $jsonContainer.after($reactContainer);

                ymaps.geolocation.get({
                    provider: 'yandex',
                    autoReverseGeocode: true,
                }).then(function (result) {
                    const address = result.geoObjects.get(0).properties.get('metaDataProperty').GeocoderMetaData.Address.Components;
                    const location = getRegion(data.countries, address);

                    getPointsAndRender({
                        location,
                        data,
                        $reactContainer,
                    });
                }).catch(function() {
                    ymaps.geolocation.get({
                        provider: 'yandex',
                        autoReverseGeocode: false,
                    }).then(function () {
                        getPointsAndRender({
                            location: {
                                countryId: 0,
                                id: 0,
                                provinceId: 0,
                            },
                            data,
                            $reactContainer,
                        });
                    });
                });
            });
        });

    });
};
