const React = require('react');
const ProductImages = require('./ProductImages.jsx');

const round = require('./round');
const InputState = require('./InputState');

class Calc4 extends React.Component {
	constructor(props) {
		super(props);

		this.state = InputState.getState();
	}

	componentDidUpdate() {
		InputState.saveState(this.state);
	}

	render() {
		const lang = this.props.lang;

		// формулы от клиента
		const logArea = 2 * Math.PI * this.state.logDiameter / 100 / 2;
		const logCountInCube = Math.pow(1/(this.state.logDiameter/100), 2);

		const substanceWeight = logCountInCube*logArea*this.state.cubesCount*this.props.consumption/this.props.concentration;


		return <div>
			<div className="calc__columns calc__columns-short">
				<div className="calc__column">
					<div className="calc__group">
						<div className="calc__label">{lang.logDiameter}:</div>
						<div className="calc__value">
							<input
								className="calc__area"
								type="text"
								value={this.state.logDiameter}
								onChange={event => {
										let val = event.target.value;

										if((+val && +val > 0) || val === '') {
											this.setState({
												logDiameter : +val || ''
											});
										}
									}}
							/>
							{lang.logDiameterUnit}
						</div>
					</div>
				</div>
				<div className="calc__column">
					<div className="calc__group">
						<div className="calc__label">{lang.cubesCount}:</div>
						<div className="calc__value">
							<input
								className="calc__layers"
								type="text"
								value={this.state.cubesCount}
								onChange={event => {
										let val = event.target.value;

										if((+val && +val > 0) || val === '') {
											this.setState({
												cubesCount : +val || ''
											});
										}
									}}
							/>
							{lang.cubesCountUnit}
						</div>
					</div>
				</div>
			</div>
			{this.state.logDiameter && this.state.cubesCount && <div className="calc__columns">
				<div className="calc__column">
					<div>
						<h4>{lang.calcResult}:</h4>

						<ul>
							<li>
								{lang.logArea}: <strong>{round(logArea)} {lang.areaUnit}</strong>
							</li>
							<li>
								{lang.logCountInCube}: <strong>{round(logCountInCube)} {lang.countUnit}</strong>
							</li>
							<li>
								{lang.substanceWeight}: <strong>{round(substanceWeight)} {lang.substanceWeightUnit}</strong>
							</li>
						</ul>
					</div>
					&nbsp;
				</div>
				<div className="calc__column">
					{this.props.images &&
					<ProductImages lang={lang} items={this.props.images} link={this.props.link} />
					}
				</div>
			</div>}
		</div>
	}
}

Calc4.propTypes = {
	name : React.PropTypes.string,
	consumption : React.PropTypes.arrayOf(React.PropTypes.number),
	concentration : React.PropTypes.number,
	link : React.PropTypes.string,
	images : React.PropTypes.arrayOf(
		React.PropTypes.shape({
			url : React.PropTypes.string,
			caption : React.PropTypes.string
		})
	),
	lang : React.PropTypes.objectOf(React.PropTypes.string)
};


module.exports = Calc4;