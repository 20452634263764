require('bootstrap/js/affix');

function init() {
	$('[main-bar-affix]').each(function () {
		$(this).affix({
			offset: {
				top: 109
			}
		});
	});
}

module.exports = init;