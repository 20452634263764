(function (factory) {
	if (typeof define === 'function' && define.amd) {
		// AMD. Register as an anonymous module.
		define(['jquery'], factory);
	} else if (typeof exports === 'object') {
		// Node/CommonJS
		module.exports = factory(require('jquery'));
	} else {
		// Browser globals
		factory(jQuery);
	}
}(function ($) {
	"use strict";

	var pluginName = 'collapse',
		defaults = {
			openDuration  : 200,
			closeDuration : 200,
			preventDefault : true,
			animation : true,
			wrapper : null,
			button : null,
			siblings : null,
			customClass : {
				open : 'collapse-open'
			}
		},
		$window = $(window),
		uid = 0;

	function Plugin ( element, options ) {
		this.$element = element;
		this.settings = $.extend( {}, defaults, options );
		this._defaults = defaults;
		this._name = pluginName;
		this.uid = uid++;
		this.init();
	}

	$.extend(Plugin.prototype, {
		init : function () {
			this.$element.trigger(pluginName+'.beforeInit');

			var _this = this,
				$element = this.$element,
				$group = $element.closest('[collapse-group]');

			this.settings.button = this.settings.button || $element.find('[collapse-button]');
			this.settings.wrapper = this.settings.wrapper || $element.find('[collapse-content]');
			this.settings.siblings = this.settings.siblings || $group.length && $group.find('[collapse]').not($element);


			if(!$element.hasClass(this.settings.customClass.open)) {
				this.settings.wrapper.hide();
				this.isOpen = false;
			} else {
				this.settings.wrapper.show();
				this.isOpen = true;
			}

			this.settings.button.on('click.collapse', this.buttonClickHandler.bind(this));
			$window.on('click.collapse-uid-' + this.uid, this.windowClickHandler.bind(this));

			this.$element.addClass('collapse-init');

			this.$element.trigger(pluginName+'.afterInit');
		},

		destroy : function () {
			this.settings.button.off('click.collapse');
			$window.off('click.collapse-uid-' + this.uid);
			this.$element.removeClass('collapse-init');
			this.settings.wrapper.attr('style', '');
			$.data( this.$element[0], 'plugin_' + pluginName, null );
			this.$element.trigger(pluginName+'.destroy');
		},


		open : function () {
			if(this.isOpen) return;

			this.$element.trigger(pluginName+'.beforeOpen');

			if(this.settings.animation) {
				this.settings.wrapper
					.stop(true, true)
					.fadeIn({
						duration: this.settings.openDuration,
						queue: false
					})
					.css('display', 'none')
					.slideDown(this.settings.openDuration);
			} else {
				this.settings.wrapper.show();
			}


			this.$element.addClass(this.settings.customClass.open);
			this.settings.button.addClass(this.settings.customClass.open);
			this.isOpen = true;

			if(this.settings.siblings) {
				this.settings.siblings[pluginName]('close');
			}

			this.$element.trigger(pluginName+'.afterOpen');
		},


		close : function () {
			if(!this.isOpen) return;
			this.$element.trigger(pluginName+'.beforeClose');

			if(this.settings.animation) {
				this.settings.wrapper
					.stop(true, true)
					.fadeOut({
						duration: this.settings.closeDuration,
						queue: false
					})
					.slideUp(this.settings.closeDuration);
			} else {
				this.settings.wrapper.hide();
			}

			this.$element.removeClass(this.settings.customClass.open);
			this.settings.button.removeClass(this.settings.customClass.open);
			this.isOpen = false;

			this.$element.trigger(pluginName+'.afterClose');
		},

		buttonClickHandler : function (event) {
			if(this.isOpen) {
				if(this.settings.preventDefault) event.preventDefault();
				this.close();
			} else {
				if(this.settings.preventDefault) event.preventDefault();
				this.open();
			}
		},

		windowClickHandler : function (event) {
			if(!this.isOpen || this.settings.wrapper.css('position') != 'absolute') return;

			var $target = $(event.target);  
			var $closestElems = this.settings.siblings ? this.$element.add(this.settings.siblings) : this.$element;

			if(!$target.closest($closestElems).length) {
				this.close();
			}
		}

	});

	$[ pluginName ] = Plugin;


	$.fn[ pluginName ] = function ( arg ) {
		this.each(function() {
			if ( !$.data( this, 'plugin_' + pluginName ) ) {
				$.data( this, 'plugin_' + pluginName, new Plugin( $(this), arg ) );
			} else {
				if(typeof arg == 'string') {
					$(this).data('plugin_' + pluginName)[arg]();

				}
				if(typeof arg == 'object') {
					$.extend($(this).data('plugin_' + pluginName).settings, arg);
				}
			}
		});

		return this;
	};
}));