"use strict";

var moduleName = 'collapse';

require('./collapsePlugin');

module.exports = function () {
	$('[collapse]').each(function () {
		if ($.data(this, moduleName + '-init')) return;
		$.data(this, moduleName + '-init', true);

		$(this).collapse();

	});
};

