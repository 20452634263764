const React = require('react');
const Select = require('react-select');
const Calc1 = require('./Calc1.jsx');
const Calc2 = require('./Calc2.jsx');
const Calc3 = require('./Calc3.jsx');
const Calc4 = require('./Calc4.jsx');


class App extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			material : this.props.data.items.length > 1 ? null : this.props.data.items[0]
		}
	}

	onSelect(material) {
		this.setState({
			material
		})
	}

	render() {
		var material = this.state.material,
		    materials = this.props.data.items,
		    lang = this.props.data.lang;


		return <div className="calc">

			{materials.length > 1 && <div className="calc__group calc__group-short">
				<div className="calc__label">{lang.title}:</div>
				<div className="calc__value">
					<Select
						className="calc__select"
						name="form-field-name"
						valueKey="name"
						labelKey="name"
						options={materials}
						placeholder={lang.placeholder}
						value={material && material.name}
						onChange={this.onSelect.bind(this)}
					/>
				</div>
			</div>}

			{materials.length == 1 && <div>
				<h4>{lang.singleTitle} “{materials[0].name}”</h4>
			</div>}

			{material && material.mode === 1  && <Calc1 {...material} lang={lang} />}
			{material && material.mode === 2  && <Calc2 {...material} lang={lang} />}
			{material && material.mode === 3  && <Calc3 {...material} lang={lang} />}
			{material && material.mode === 4  && <Calc4 {...material} lang={lang} />}
		</div>
	}
}

App.propTypes = {
	data : React.PropTypes.shape({
		lang : React.PropTypes.objectOf(React.PropTypes.string),
		items : React.PropTypes.arrayOf(
			React.PropTypes.shape({
				mode : React.PropTypes.oneOf([1, 2, 3, 4]),
				name : React.PropTypes.string,
				consumption : React.PropTypes.arrayOf(React.PropTypes.number),
				rate : React.PropTypes.number,
				link : React.PropTypes.string,
				images : React.PropTypes.arrayOf(
					React.PropTypes.shape({
						url : React.PropTypes.string,
						caption : React.PropTypes.string
					})
				)
			})
		)
	})
};


module.exports = App;