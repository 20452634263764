"use strict";

module.exports = function () {
	$('.contacts__scrollto').on('click', function(){
		var goalTop = $($(this).attr('href')).offset().top;

		$('html, body').animate({
			scrollTop: goalTop
		}, 300);

		return false;
	});
};