'use strict';

const moduleName = 'material-calc';

const React    = require('react');
const ReactDOM = require('react-dom');

const App = require('./App.jsx');

module.exports = function () {
	$('[material-calc]').each(function () {
		if ($.data(this, moduleName + '-init')) return;
		$.data(this, moduleName + '-init', true);

		const $jsonContainer  = $(this);
		const $reactContainer = $('<div>');
		const data            = JSON.parse($jsonContainer.html());

		$jsonContainer.after($reactContainer);

		ReactDOM.render(<App data={data} />, $reactContainer[0]);
	});
};