const React = require('react');
const Tooltip = require('./Tooltip.jsx');
const ProductImages = require('./ProductImages.jsx');

const round = require('./round');
const InputState = require('./InputState');

class Calc1 extends React.Component {
	constructor(props) {
		super(props);

		this.state = InputState.getState();
	}

	componentDidUpdate() {
		InputState.saveState(this.state);
	}

	render() {
		const inputArea = this.state.inputArea;
		const lang = this.props.lang;
		const concentrateVol = [
			round(this.props.consumption[0] * inputArea * this.props.rate),
			round(this.props.consumption[1] * inputArea * this.props.rate)
		];
		const vol = concentrateVol.map(val => round(val/this.props.rate));




		return <div>
			<div className="calc__columns calc__columns-short">
				<div className="calc__column">
					<div className="calc__group">
						<div className="calc__label">{lang.areaTitle}:</div>
						<div className="calc__value">
							<input
								className="calc__area"
								type="text"
								value={inputArea}
								onChange={event => {
										let val = event.target.value;

										if((+val && +val > 0) || val === '') {
											this.setState({
												inputArea : +val || ''
											});
										}
									}}
							/>
							{lang.areaUnit}
						</div>
					</div>
				</div>
			</div>
			{!!inputArea && <div className="calc__columns">
				<div className="calc__column">
					<div>
						<h4>{lang.calcResult}:</h4>
						<ul>
							<li>{lang.area}: <strong>{inputArea} {lang.areaUnit}</strong></li>
							<li>
								{lang.consumption}: <strong>{lang.from} {this.props.consumption[0] * 1000} {lang.to} {this.props.consumption[1] * 1000} {lang.consumptionUnit}</strong>
								<Tooltip className="calc__tooltip">
									{lang.tooltip}
								</Tooltip>
							</li>
							<li>
								{lang.amountSolution}: <strong>{lang.from} {vol[0]} {lang.to} {vol[1]} {this.props.unit || lang.volumeUnit}</strong>
							</li>
							<li>
								{lang.amountConcentrate}: <strong>{lang.from} {concentrateVol[0]} {lang.to} {concentrateVol[1]} {this.props.unit || lang.volumeUnit}</strong>
							</li>
						</ul>
					</div>
					&nbsp;
				</div>
				<div className="calc__column">
					{this.props.images &&
					<ProductImages lang={lang} items={this.props.images} link={this.props.link} />
					}
				</div>
			</div>}
		</div>
	}
}

Calc1.propTypes = {
	name : React.PropTypes.string,
	consumption : React.PropTypes.arrayOf(React.PropTypes.number),
	rate : React.PropTypes.number,
	link : React.PropTypes.string,
	images : React.PropTypes.arrayOf(
		React.PropTypes.shape({
			url : React.PropTypes.string,
			caption : React.PropTypes.string
		})
	),
	lang : React.PropTypes.objectOf(React.PropTypes.string)
};


module.exports = Calc1;