module.exports = function (selector, url = location.href, cb = function(){} ) {
	return $.get(url, 'html')
		.then(html => {
			let $newPage = $(html),
				$block = $(selector),
				$newBlock = $newPage.find(selector);

			if(!$block.length) throw 'selector not found on this page';
			if(!$newBlock.length) throw 'selector not found on response page';

			$block.html($newBlock.html());

			cb();
		})
};