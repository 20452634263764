require('tooltipster');
var React = require('react');

class Tooltip extends React.Component {
	constructor(props) {
		super(props)
	}

	componentDidMount() {
		$(this.refs.main).tooltipster({
			arrow : false,
			content : $(this.refs.content),
			trigger : 'click',
			position : 'top',
			animation : 'fade',
			delay : 0,
			contentCloning : true,
			interactive : true
		});
	}

	render() {
		return <div className={'m-tooltip' + (this.props.className ? ' ' + this.props.className : '')} ref="main">
			<div className="m-tooltip__content" ref="content">
				{this.props.children}
			</div>
		</div>
	}
}

module.exports = Tooltip;