const React = require('react');

class ProductImages extends React.Component {
	render() {
		return <div>
			<h4>{this.props.lang.packagingOptions}:</h4>
			<div className="stock">
				{this.props.items.map((item, i) => <div className="stock__item" key={i}>
					<a href={this.props.link}>
						<img className="stock__pic" src={item.url} />
					</a>
					<div className="stock__caption">{ item.caption }</div>
				</div>)}
			</div>
		</div>
	}
}

ProductImages.propTypes = {
	link : React.PropTypes.string,
	items : React.PropTypes.arrayOf(
		React.PropTypes.shape({
			url : React.PropTypes.string,
			caption : React.PropTypes.string
		})
	),
	lang : React.PropTypes.shape({
		packagingOptions : React.PropTypes.string
	})
};

module.exports = ProductImages;