"use strict";
require('swiper');

module.exports = function () {
	$('.promo')
		.append('<div class="promo__pagination"></div><div class="promo__prev"></div><div class="promo__next"></div>')
		.swiper({
			loop: true,
			pagination: '.promo__pagination',
			paginationClickable: true,
			autoplay: 6000,
			speed: 1000,
			prevButton: '.promo__prev',
			nextButton: '.promo__next',
			simulateTouch: false,
			bulletClass: 'promo__bullet',
			bulletActiveClass: 'promo__bullet_active'
		});
};
