"use strict";
require('swiper');

module.exports = function () {
	$('.related')
		.append('<div class="related__prev"></div><div class="related__next"></div>')
		.swiper({
			slidesPerView: 'auto',
			loop: false,
			prevButton: '.related__prev',
			nextButton: '.related__next'
		});
};