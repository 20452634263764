"use strict";
require('swiper');

module.exports = function () {
	$('.hits__slider')
		.append('<div class="hits__prev" /><div class="hits__next" />')
		.swiper({
			slidesPerView: 'auto',
			loop: false,
			prevButton: '.hits__prev',
			nextButton: '.hits__next'
		});
};