const React = require('react');
const ProductImages = require('./ProductImages.jsx');

const round = require('./round');
const InputState = require('./InputState');

class Calc2 extends React.Component {
	constructor(props) {
		super(props);

		this.state = InputState.getState();
	}

	componentDidUpdate() {
		InputState.saveState(this.state);
	}

	render() {
		const lang = this.props.lang;
		const vol = [
			round(this.state.inputArea / this.props.consumption[0] * this.state.inputCount),
			round(this.state.inputArea / this.props.consumption[1] * this.state.inputCount)
		];


		return <div>
			<div className="calc__columns calc__columns-short">
				<div className="calc__column">
					<div className="calc__group">
						<div className="calc__label">{lang.areaTitle}:</div>
						<div className="calc__value">
							<input
								className="calc__area"
								type="text"
								value={this.state.inputArea}
								onChange={event => {
										let val = event.target.value;

										if((+val && +val > 0) || val === '') {
											this.setState({
												inputArea : +val || ''
											});
										}
									}}
							/>
							{lang.areaUnit}
						</div>
					</div>
				</div>
				<div className="calc__column">
					<div className="calc__group">
						<div className="calc__label">{lang.layersTitle}:</div>
						<div className="calc__value">
							<input
								className="calc__layers"
								type="text"
								value={this.state.inputCount}
								onChange={event => {
										let val = event.target.value;

										if((+val && +val > 0) || val === '') {
											this.setState({
												inputCount : +val || ''
											});
										}
									}}
							/>
						</div>
					</div>
				</div>
			</div>
			{this.state.inputArea && this.state.inputCount && <div className="calc__columns">
				<div className="calc__column">
					<div>
						<h4>{lang.calcResult}:</h4>

						<ul>
							<li>{lang.area}: <strong>{this.state.inputArea} {lang.areaUnit}</strong></li>
							<li>
								{lang.areaOneUnit.replace('{unit}', this.props.unit || lang.volumeUnit)}: <strong>{lang.from} {this.props.consumption[0]} {lang.to} {this.props.consumption[1]} {lang.areaUnit}</strong>
							</li>
							{
								this.props.consumption[0] && this.props.consumption[1] ?
								<li>{lang.amountCompound}: <strong>{lang.from} {vol[1]} {lang.to} {vol[0]} {this.props.unit || lang.volumeUnit}</strong></li> : ''
							}
						</ul>
					</div>
					&nbsp;
				</div>
				<div className="calc__column">
					{this.props.images &&
					<ProductImages lang={lang} items={this.props.images} link={this.props.link} />
					}
				</div>
			</div>}
		</div>
	}
}

Calc2.propTypes = {
	name : React.PropTypes.string,
	consumption : React.PropTypes.arrayOf(React.PropTypes.number),
	link : React.PropTypes.string,
	images : React.PropTypes.arrayOf(
		React.PropTypes.shape({
			url : React.PropTypes.string,
			caption : React.PropTypes.string
		})
	),
	lang : React.PropTypes.objectOf(React.PropTypes.string)
};


module.exports = Calc2;