"use strict";
var $ = window.jQuery = window.$ = require('jquery');

require('./modules/polyfills');

window.ajaxPageUpdate = require('./modules/ajaxPageUpdate');

require('./modules/popup');
require('./modules/formValidation');

var initCustomWidgets = function () {
	require('./modules/navInit')();
	require('./modules/ctlgInit')();
	require('./modules/hitsSliderInit')();
	require('./modules/responsiveTableInit')();
	require('./modules/magnificInit')();
	require('./modules/tabsInit')();
	require('./modules/relatedSliderInit')();
	require('./modules/popoverInit')();
	require('./modules/newQuestionInit')();
	require('./modules/scrollToInit')();
	require('./modules/contactsToggleInit')();
	require('./modules/set-background')();
	require('./modules/promoSliderInit')();
	require('./modules/headerSearchInit')();
	require('./pointsMap/pointsMapInit.jsx')();
	require('./materialCalc/materialCalcInit.jsx')();
	require('./modules/collapseInit')();
	require('./modules/mainBarAffixInit')();
};

$(initCustomWidgets);
