const initialState = {
	inputArea : '',
	inputCount : 1,
	logDiameter : '',
	cubesCount : 1,
	boardWidth : '',
	boardThickness : '',
	calc3mode : 1
};
let state;

try {
	state = JSON.parse(window.localStorage.getItem('calcInputState')) || initialState;
} catch (e) {
	state = initialState;
}



exports.getState = function () {
	return Object.assign({}, state);
};

exports.saveState = function (data) {
	state = Object.assign({}, state, data);
	window.localStorage.setItem('calcInputState', JSON.stringify(state));
};