"use strict";

module.exports = function () {
	$('.help').on('click', function(){
		var $help = $(this),
			$popover = $('<div class="popover">' +
						 '<button title="Закрыть" class="popover__close"></button>' +
						 '<div class="popover__caption">' + $help.data('caption') + '</div>' +
						 $help.data('text') +
						 '<i class="popover__arrow"/>' +
						 '</div>');

		$('body').append($popover);

		var	$arrow = $popover.find('.popover__arrow'),
			tOffset = $help.offset().top - $popover.outerHeight() - $help.height()/2,
			pOffset = $popover.width()/2 + $help.width()/2,
			hOffset = $help.offset().left,
			offsetDelta = hOffset - pOffset;

		$popover.css('top', tOffset);

		if (offsetDelta > 0) {
			$popover.css('left', offsetDelta);
			$arrow.css('left', $popover.width()/2 + $help.width()/2);
		} else {
			$popover.css('left', 0);
			$arrow.css('left', hOffset);
		}
	});

	function popoverClose() {
		$('.popover').remove();
	}

	$('.popover__close').on('click', popoverClose);
	$(window).on('resize', popoverClose);
	$(document).on('click', function(e){
		if ($(e.target).parents('.popover') && !$(e.target).is('.help') && !$(e.target).is('.popover')) {
			popoverClose();
		}
	});
};