require('magnific-popup');

var moduleName = 'mfp-popup';


var $onloadPopup = $(location.hash);

if($onloadPopup.length && $onloadPopup.is('[popup]')) {
	showPopup($onloadPopup, true);
}

$(window).on('popstate', function (event) {
	var hash = event.originalEvent.state && event.originalEvent.state.mfpHash;

	if(hash) {
		showPopup($(hash))
	} else if($.magnificPopup.instance.content) {
		$.magnificPopup.close()
	}
});

$('body')
	.on(`click.${moduleName}`, '[popup-link]', function (event) {
		event.preventDefault();

		var $link = $(event.currentTarget);
		var href = $link.attr('href');
		var $popup =  $(href);

		if(href.charAt(0) === '#') {
			history.replaceState({mfpHash: ''}, null);
			history.pushState({mfpHash: href}, null, href);
		}

		showPopup($popup);
	})
	.on(`click.${moduleName}`, '[popup-close]', function (event) {
		event.preventDefault();
		$.magnificPopup.close();
	});

function showPopup($popup, onPageLoad = false) {
	$.magnificPopup.open({
		items: {
			src: $popup,
			type: 'inline'
		},
		callbacks: {
			[onPageLoad ? 'beforeClose' : 'beforeOpen']: function() {
				this.st.mainClass = 'mfp-zoom-in';
			},
			afterClose: function () {
				history.pushState({mfpHash: ''}, null, '#');
			}
		},
		removalDelay: onPageLoad ? null : 300
	});
}