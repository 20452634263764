"use strict";

require('typeahead.js');

var moduleName = 'header-search';

module.exports = function () {
	$('[header-search]').each(function () {
		if($.data(this, moduleName + '-init')) return;
		$.data(this, moduleName + '-init', true);

		var $input = $(this),
		    $wrap = $input.closest('.search'),
			url = $input.attr('header-search');


		$input.typeahead(null, {
			display: 'name',
			limit: 5,
			async : true,
			templates: {
				suggestion: (data) => `
					<div class="search__result">
						<div class="search__result-photo">
							<img class="search__result-photo-img" src="${data.img}">
						</div>
						<div class="search__result-text">
							${data.name}
						</div>
					</div>`
			},
			source: function (query, syncResults, asyncResults) {
				$.ajax({
					url : url,
					type : 'GET',
					data : {
						string: query,
						action : 'search'
					},
					dataType : 'json'
				}).then(function (response) {
					asyncResults(response);
				});
			}
		});

		$input
			.on('typeahead:render', function (e, data) {
				if(data) {
					$wrap.addClass('tt-rendered');
				} else {
					$wrap.removeClass('tt-rendered');
				}
			})
			.on('typeahead:open', function () {
				$wrap.addClass('tt-open');
			})
			.on('typeahead:close', function () {
				$wrap.removeClass('tt-open');
			})
			.on('typeahead:select', function (e, data) {
				if(data.link) {
					window.location.href = data.link;
				}
			});
	});
};