"use strict";

module.exports = function () {
	$('.nav__link').on('click', function(){
		var $item = $(this).parent('.nav__item');

		if ($item.children('.nav__sm').length) {
			$item.siblings('.nav__item_open').removeClass('nav__item_open');
			$item.toggleClass('nav__item_open');
			return false;
		}
	});
	$(document).on('click', function(e){
		if ($(e.target).parents('.nav__item_open')) {
			$('.nav__item_open').removeClass('nav__item_open');
		}
	});

	$('.nav__toggle').on('click', function(){
		$(this).parent('.nav').toggleClass('nav_open');
	});
};